











































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

import CounterpartyResponseModel from "@/accounting-portal/models/counterparties/CounterpartyResponseModel";
import { AppSection } from "@/shared/models";

@Component
export default class CounterpartyHeader extends Vue {
  @Prop() hasEditAccess!: boolean;
  @Prop() hasDeleteAccess!: boolean;

  readonly AppSection = AppSection;
  isShowMore = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get counterparty(): CounterpartyResponseModel | null {
    return this.$store.state.counterpartyStore.counterparty;
  }

  get counterpartyName(): string {
    return this.counterparty?.name ?? "";
  }

  get currencyCode(): string {
    return this.counterparty?.currencyCode.toUpperCase() ?? "";
  }

  get bankName(): string {
    return this.counterparty?.currentBankName ?? "";
  }

  get currentNet(): string {
    if (this.counterparty?.currentNet) {
      const { net, dateFrom, dateTo } = this.counterparty.currentNet;
      const dates = dateTo ? `${dateFrom} - ${dateTo}` : `${dateFrom}`;

      return `${dates}, ${net} NET`;
    }

    return "";
  }

  get taxation(): string {
    return this.counterparty?.taxation
      ? this.$lang(
          `accountingPortal.counterparties.taxation.${this.counterparty.taxation.toLowerCase()}`
        )
      : "";
  }

  get address(): string {
    return this.counterparty?.address
      ? Object.values(this.counterparty.address).join(", ")
      : "";
  }

  get websiteUrl(): string {
    return this.counterparty?.websiteUrl ?? "";
  }

  get note(): string {
    if (this.counterparty?.note) {
      if (this.isShowMore || this.counterparty.note.length <= 100) {
        return this.counterparty.note;
      }

      return `${this.counterparty.note.slice(0, 100)}...`;
    }

    return "";
  }
}
